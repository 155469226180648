<!-- Progress spinner -->
@if (isProgress) {
  <ui-progress-bar></ui-progress-bar>
}

<div class="inner">
  <!-- Mobile menu -->
  @if (!isMobileSearchVisible) {
    <!-- Menu button -->
    @if (showMobileMenu()) {
      <div class="btn-menu-mobile" (click)="toggleMenu()">
        @if (isMenu) {
          <div class="ui-icon-menu-close"></div>
        } @else {
          <div class="ui-icon-menu-burger"></div>
        }
      </div>
    }

    <!-- Logo -->
    <a class="logo" [routerLink]="url()">
      <img [src]="logo()" alt="logo">
    </a>
  }

  <!-- Search -->
  @if (showSearch()) {
    <div #fade class="input-fade" style="display: none"></div>
    <ui-search
      #searchComponent
      [class.mobile-visible]="isMobileSearchVisible"
      [showButton]="false"
      [showIcon]="true"
      (focus)="onSearchFocus(fade)"
      (blur)="onSearchBlur(fade)"
      (onSearch)="doSearch($event)"
    ></ui-search>
  }

  <!-- Mobile search button -->
  @if (!isMobileSearchVisible && showSearch()) {
    <span
      class="btn btn-mobile-search"
      (click)="showMobileSearch(searchComponent())"
    >
      <div class="ui-icon-search-blue"></div>
    </span>
  }

  <!-- Mobile user profile -->
  @if (!isMobileSearchVisible && isLogged()) {
    <span
      [class.active]="selSection?.id === 'profile'"
      [class.no-search]="!showSearch()"
      class="btn mobile-ava-profile"
      (click)="toggleMobileProfileMenu($event)"
    >
      <ui-avatar [name]="userName()" [avatar]="userAvatar()"></ui-avatar>
    </span>
  }

  <!-- Menu items -->
  <div class="items">
    <div class="menu-block">
      <!-- Main section -->
      @for (item of menuDesc; track item.id || $index) {
        @if (item.externalUrl) {
          <a
            class="btn ui-link ui-second"
            [href]="item.externalUrl"
            [class.active]="selSection === item"
            [class.new-indicator]="item.isIndicator"
            [style.order]="$index"
            (click)="selectSection(item, $event)"
            target="_blank"
          >@if (item.icon) {
            <img class="menu-icon" [src]="item.icon">
          }
            {{ item.title }}
          </a>
        } @else {
          <a
            class="btn ui-link ui-second"
            [routerLink]="item.url"
            [routerLinkActive]="item.useRouteForActiveState ? 'active' : []"
            [routerLinkActiveOptions]="{exact: true}"
            [queryParams]="item.query"
            [class.active]="selSection === item && !item.useRouteForActiveState"
            [class.new-indicator]="item.isIndicator"
            [class.avatar]="item.showAvatar"
            [style.order]="$index"
            (click)="selectSection(item, $event)"
          >
            @if (item.icon) {
              <img class="menu-icon" [src]="item.icon">
            }
            @if (item.showAvatar) {
              <ui-avatar [name]="userName()" [avatar]="userAvatar()"></ui-avatar>
            } @else {
              {{ item.title }}
            }
          </a>
        }
      }

      <!-- Notifications -->
      @if (notificationsButton()) {
        <a
          class="btn ui-link ui-second btn-notifications"
          [class.active]="selSection === notifications() && !notifications().useRouteForActiveState"
          [style.order]="menuDesc.length - 2"
          (click)="requestNotifications($event)"
        >
          <div class="ui-icon-subscribe"></div>
          @if (notificationCount()) {
            <div class="notify-counter">{{notificationCount()}}</div>
          }
        </a>
      }

      <!-- Notifications popup -->
      @if (selSection === notifications() && notificationData) {
        @defer (on immediate) {
          <ui-notification-popup @notificationPopupAnimation [items]="notificationData" (action)="onNotificationPopupAction($event)"></ui-notification-popup>
        }
      }
    </div>

    <!-- Auth -->
    @if (!isLogged()) {
      <div class="auth-block hide-on-mobile">
        <span class="btn ui-link ui-second" (click)="onLoginClick()">Login</span>
        <button class="btn-outline btn-register" (click)="onRegisterClick()">Get started</button>
      </div>
    }
  </div>

  <!-- Profile menu -->
  @if (selSection && selSection.id === profileItemName()) {
    <div class="profile-menu" @profileMenuAnimation>
      <a class="avatar-section">
        <ui-avatar [name]="userName()" [avatar]="userAvatar()"></ui-avatar>
        {{selSection.titleDesc}}
      </a>
      @for (item of selSection.items; track $index) {
        <a
          [class.link]="item.style === 'link'"
          [class.separator]="item.separator"
          [routerLink]="item.url"
          [queryParams]="item.query"
          (click)="item.click ? item.click() : undefined"
        >
          {{ item.title }}
        </a>
      }
    </div>
  }
</div>

<!-- Second section -->
@if (selSection && selSection.id !== profileItemName() && selSection !== notifications()) {
  <!--  Regular menu  -->
  <div class="second-section" [class.with-buttons]="selSection.buttons?.length || selSection.buttons?.length">
    <div class="inner">
      <h1>
        {{ selSection.title }}
        <div>{{ selSection.titleDesc }}</div>
      </h1>
      <div>
        @if (selSection.desc) {
          <div class="txt-desc">{{ selSection.desc }}</div>
        }
        <div>
          @for (item of selSection.items; track $index) {
            @if (item.url) {
              <a class="btn-arrow" [routerLink]="item.url" [queryParams]="item.query">
                {{ item.title }}
                <span class="ui-icon-arrow-right"></span>
              </a>
            }
            @if (item.externalUrl) {
              <a class="btn-arrow" [href]="item.externalUrl" target="_blank" rel="noopener noreferrer">
                {{ item.title }}
                <span class="ui-icon-arrow-right"></span>
              </a>
            }
          }
        </div>
        <div>
          @for (btn of selSection.buttons; track btn.id || $index) {
            <button class="btn-primary btn-in-section" (click)="btn?.click && btn?.click(); toggleMenu()">
              {{ btn.title }}
            </button>
          }
        </div>
      </div>
      <span class="btn btn-close" (click)="selSection = undefined">
      <div class="ui-icon-close"></div>
    </span>
    </div>

    <!-- Third section -->
    @if (selSection.thirdSection; as third) {
      <div class="third-section">
        <div class="inner">
          <!-- Columns -->
          @for (col of third.columns; track $index) {
            <div [style.grid-column]="col.isGrid ? 'span 4' : ''">
              <!-- Title -->
              <div class="col-title">{{ col.title }}</div>
              <!-- Items list -->
              <ul class="col-items" [class.col-grid]="col.isGrid">
                @for (ci of col.items; track $index) {
                  <!-- Router link -->
                  @if (ci.url) {
                    <a
                      class="ui-link ui-second col-item"
                      [routerLink]="ci.url"
                      [queryParams]="ci.query"
                    >
                      {{ ci.title }}
                    </a>
                  }
                  <!-- External link -->
                  @if (ci.externalUrl) {
                    <a
                      [href]="ci.externalUrl"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="ui-link ui-second col-item"
                    >
                      {{ ci.title }}
                    </a>
                  }
                }
              </ul>
            </div>
          }
          <!-- Banner -->
          @if (third.banner; as banner) {
            <div class="banner">
              <span>{{ banner.title }}</span>

              <!-- Router link -->
              @if (banner.url || banner.click) {
                <a
                  class="ui-link ui-second btn-arrow"
                  [routerLink]="banner.url"
                  (click)="banner.click && banner.click()"
                >
                  {{ banner.link }}
                </a>
              }

              <!-- External link -->
              @if (banner.externalUrl || !banner.click) {
                <a
                  class="ui-link ui-second btn-arrow"
                  [href]="banner.externalUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ banner.link }}
                </a>
              }
            </div>
          }
        </div>
      </div>
    }
  </div>
}

<!-- Mobile menu -->
@if (isMenu) {
  <div class="menu-mobile" @notificationPopupAnimation>
    <!-- Item -->
    @for (item of menuDesc; track (item.id || item.title || $index)) {
      <ng-container
        [ngTemplateOutlet]="mobileMenuItem"
        [ngTemplateOutletContext]="{item: item, level: 0}">
      </ng-container>
    }
    <!-- Buttons -->
    <!--  TODO: implement buttons -->
    <!-- @for (item of selSection?.buttons; track item.id) {
       <span
         class="btn ui-link ui-second btn-mobile level0"
       >
         {{item.title}}
       </span>
     }-->

    <!-- Login / Register -->
    @if (!isLogged()) {
      <ng-container>
        <span class="btn ui-link ui-second btn-mobile pad-20" (click)="onLoginClick()">Login</span>
        <span class="btn ui-link ui-second btn-mobile pad-20" (click)="onRegisterClick()">Get started</span>
      </ng-container>
    }
  </div>
}
<ng-template #mobileMenuItem let-item="item" let-level=level>
  <div
    class="btn btn-mobile"
    [class.hide-on-mobile]="item.hideOnMobile"
    [class.expandable]="item.items?.length"
    [class.expanded]="item.expanded"
    [ngClass]="'level'+level"
    (click)="item.click ? item.click() : expandItem(item)"
  >
    @if (item.externalUrl) {
      <!-- External link -->
      <a
        class="ui-link ui-second"
        [href]="item.externalUrl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span [style.padding-left.px]="level * 20">{{ item.title }}</span>
      </a>
    } @else {
      <!-- Router link -->
      <a
        class="ui-link ui-second"
        [routerLink]="item.url"
        [queryParams]="item.query"
      >
        <span [style.padding-left.px]="level * 20" [class.new-indicator]="item.isIndicator">{{ item.title }}</span>
      </a>
    }
  </div>

  <!-- Child items -->
  @if (item.expanded) {
    @for (child of item?.items; track $index) {
      <ng-container
        [ngTemplateOutlet]="mobileMenuItem"
        [ngTemplateOutletContext]="{item: child, level: level + 1}">
      </ng-container>
    }

    <!-- Third section -->
    @for (third of item?.thirdSection?.columns; track $index) {
      <ng-container
        [ngTemplateOutlet]="mobileMenuItem"
        [ngTemplateOutletContext]="{item: third, level: level + 1}">
      </ng-container>
    }

    <!-- Banner -->
    @if (item?.thirdSection?.banner; as banner) {
      <ng-container
        [ngTemplateOutlet]="mobileMenuItem"
        [ngTemplateOutletContext]="{item: { title: banner.link, url: banner.url, externalUrl: banner.externalUrl, click: banner.click }, level: level + 1}">
      </ng-container>
    }
  }
</ng-template>
