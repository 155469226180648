<!-- Sidebar -->
<ui-sidebar [items]="items()" [relativeUrl]="relativeUrl" (collapse)="onSidebarCollapsed($event)"></ui-sidebar>

<div #page class="ui-portal-page" ui-view-transition="disabled1" [transitionRules]="['otherRootRoute', 'sameRoute']">
  <router-outlet></router-outlet>
</div>

<button class="btn-gray btn-scroll-up" ui-scroll-visibility="500" (click)="scrollTo()">
  <div class="ui-icon-arrow-down"></div>
</button>
