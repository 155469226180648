import {computed, Directive, inject, input} from "@angular/core";
import {ViewTransitionService} from '../services/view-transition.service';
import {Navigation, Router} from '@angular/router';


export type TransitionRule = 'none'
  | 'sameRoute' // if route url is the same
  | 'sameParent'  // if parent(previous route) is the same, eg. /admin/1 and /admin/2
  | 'otherRootRoute' // if root route is different, eg. /admin/1/2 and /user/1/2
  | 'exactRoutes' // if route url in defined list of routes `transitionRoutes`

@Directive({
  selector: '[ui-view-transition]',
  host: {
    '[style.view-transition-name]': 'viewTransitionName()'
  }
})
export class ViewTransitionDirective {
  private readonly router = inject(Router);
  private readonly trans = inject(ViewTransitionService);

  readonly transitionName = input<string>('', { alias: 'ui-view-transition' });
  readonly transitionRules = input.required<TransitionRule|TransitionRule[]>();
  readonly transitionRoutes = input<string[]>([]); // List of routes when transition is applied. Used only with `exactRoutes` rule

  protected readonly viewTransitionName = computed(() => {
    const trans = this.trans.currentTransition();
    if (!trans) {
      return '';
    }
    const current = this.router.getCurrentNavigation();
    const previous = current?.previousNavigation;
    if (!current || !previous) {
      return '';
    }

    return this.isSatisfiesAnyRule(current, previous) ? this.transitionName() : '';
  });

  private isSameParent(current: Navigation, previous: Navigation) {
    const { currSeg, prevSeg } = this.getSegments(current, previous);

    // Check if paths are identical except for the last segment
    return currSeg.length === prevSeg.length &&
      currSeg.slice(0, -1).every((seg, index) => seg.path === prevSeg[index].path);
  }

  private isOtherRootRoute(current: Navigation, previous: Navigation) {
    const { currSeg, prevSeg } = this.getSegments(current, previous);
    return currSeg[0]?.path !== prevSeg[0]?.path;
  }

  private isSameRoute(current: Navigation, previous: Navigation) {
    const { currSeg, prevSeg } = this.getSegments(current, previous);
    return currSeg.length === prevSeg.length &&
      currSeg.every((seg, index) => seg.path === prevSeg[index].path);
  }

  private getSegments(current: Navigation, previous: Navigation) {
    return {
      currSeg: current.finalUrl?.root?.children['primary']?.segments || [],
      prevSeg: previous.finalUrl?.root?.children['primary']?.segments || []
    }
  }

 /* private isSatisfiedAllRules(current: Navigation, previous: Navigation) {
    const rules = this.transitionRules();
    if (Array.isArray(rules)) {
      return rules.every(rule => this.isSatisfiedRule(rule, current, previous));
    }
    return this.isSatisfiedRule(rules, current, previous);
  }*/

  private isSatisfiesAnyRule(current: Navigation, previous: Navigation) {
    const rules = this.transitionRules();
    if (Array.isArray(rules)) {
      return rules.some(rule => this.isSatisfiedRule(rule, current, previous));
    }
    return this.isSatisfiedRule(rules, current, previous);
  }

  private isSatisfiedRule(rule: TransitionRule, current: Navigation, previous: Navigation) {
    switch (rule) {
      case 'none':
        return true;
      case 'sameParent':
        return this.isSameParent(current, previous);
      case 'otherRootRoute':
        return this.isOtherRootRoute(current, previous);
      case 'sameRoute':
        return this.isSameRoute(current, previous);
      case 'exactRoutes':
        return this.isExactRoute(current, previous);
    }
  }

  private isExactRoute(current: Navigation, previous: Navigation) {
    const { currSeg } = this.getSegments(current, previous);
    const url = '/' + currSeg.map(seg => seg.path).join('/');
    return this.transitionRoutes().some(route => route === url);
  }
}
