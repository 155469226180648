import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, viewChild} from '@angular/core';
import {ActivationEnd, ResolveFn, Router, RouterOutlet} from '@angular/router';
import {FooterComponent} from "./layout/footer/footer.component";
import {IModalData, ModalService} from "./services/modal.service";
import {ModalComponent} from "./controls/modal/modal.component";
import {ExternalHeaderComponent} from "../oex-ui-kit/components/external-header/external-header.component";
import {AuthService} from "./services/auth.service";
import {HeaderComponent, IMenu} from "../oex-ui-kit/components/header/header";
import {MetaService} from "../oex-ui-kit/services/meta.service";
import {APIService} from "./services/api.service";
import {IHeaderNotification} from "../oex-ui-kit/components/notification-popup/notification-popup.component";
import {NotificationsService} from "../oex-ui-kit/services/notifications.service";
import {ProgressService} from "../oex-ui-kit/services/progress.service";

const resolveNotifications: ResolveFn<IHeaderNotification[]> = async () => {
  const api = inject(APIService);
  return await api.getNotifications();
}

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, FooterComponent, ModalComponent, ExternalHeaderComponent, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  private modal = inject(ModalService);
  auth = inject(AuthService);
  private router = inject(Router);
  private api = inject(APIService);
  private meta = inject(MetaService);
  private cd = inject(ChangeDetectorRef);
  private ps = inject(ProgressService);
  private readonly ns = inject(NotificationsService);
  readonly header = viewChild.required(HeaderComponent);
  // readonly routerOutlet = viewChild.required(RouterOutlet);
  protected readonly resolveNotifications = resolveNotifications;
  title = 'Startup Hub';
  isFooter = false;
  modals: IModalData[] = [];
  menuItems: IMenu[] = [
    {
      id: 'profile',
      title: 'Profile',
      showAvatar: true,
      hideOnMobile: true,
      condition: () => this.auth.isLogged,
      items: [
        {
          title: 'InterSystems Startup Portal',
          url: '/portal/overview'
        }
      ],
      buttons: [
        {
          id: 'logout',
          title: 'Log out',
          condition: () => this.auth.isLogged,
          click: () => this.doLogout()
        }
      ]
    }
  ];

  get avatar() {
    return this.api.getUserAvatarUrl(this.auth.user.avatar);
  }

  ngOnInit() {
    this.meta.initialize();
    this.updateMenu();
    this.router.events.subscribe(e => {
      if (e instanceof ActivationEnd) {
        if ((e.snapshot.routeConfig?.data as any)?.hideFooter) {
          this.checkFooterVisibility(false);
        } else {
          this.checkFooterVisibility(true);
        }
      }
    });
    this.modal.onModalsChanged.subscribe(modals => {
      this.modals = modals;
      this.cd.detectChanges();
    });

    // Subscribe for notification events
    this.ns.onMarkAsRead.subscribe(ids => {
      void this.markAsRead(ids);
    });
  }

  updateMenu() {
    // Update user name
    const profile = this.menuItems.find(m => m.id === 'profile');
    if (profile) {
      profile.titleDesc = this.auth.user?.name || '';
      profile.items = [
        {
          title: 'Overview',
          url: '/portal/overview',
          condition: () => !!this.auth.user.startup
        },
        {
          title: 'Edit profile',
          url: '/portal/profile',
          condition: () => !!this.auth.user.startup
        },
        {
          title: 'Edit startup',
          url: '/portal/edit',
          condition: () => !!this.auth.user.startup
        },
        {
          title: 'Subscriptions',
          url: '/portal/subscriptions'
        },
        {
          title: 'Log Out',
          style: 'link',
          separator: true,
          condition: () => this.auth.isLogged,
          click: () => this.doLogout()
        }
      ];
    }

    /*const portal = profile?.items?.find(m => m.text === 'InterSystems Startup Portal');
    if (portal) {
      portal.url = `/portal/edit/${this.auth.user?.startup?.slug}`;
    }*/
    this.header().refresh();
  }

  onRegister() {
    this.auth.register();
  }

  onLogin() {
    void this.auth.login();
  }

  private checkFooterVisibility(visible: boolean) {
    if (visible !== this.isFooter) {
      this.isFooter = visible;
      this.cd.detectChanges();
    }
  }

  private doLogout() {
    this.auth.logout()
      .then(() => {
        this.updateMenu();
        this.cd.detectChanges();
        // Check for portal or admin panel
        const r = this.router.url.split('/')[1];
        if (r === 'admin' || r === 'portal') {
          void this.router.navigateByUrl('/');
        }
      });
  }

  private markAsRead(ids: number[]) {
    void this.ps.runWithProgress(async () => {
      await this.api.markNotificationsAsRead(ids);
      if (this.auth.user?.notifications) {
        this.auth.user.notifications -= ids.length;
        this.header().refresh();
      }
    });
  }
}
