import { ChangeDetectionStrategy, Component, computed, model, OnInit, output, viewChildren, inject } from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {IMenuButtonAction, IMenuButtonItem, MenuButtonComponent} from '../menu-button/menu-button.component';
import {DomSanitizer} from '@angular/platform-browser';
import {NotificationsService} from '../../services/notifications.service';
import {MarkdownDirective} from '../markdown/markdown.directive';
import {DatePipe} from '@angular/common';


export interface IHeaderNotification {
  id: number;
  img?: string;
  body: string;
  created: string;
  url: string;
  status?: number;
  // for UI
  actions?: IMenuButtonItem[];
}

@Component({
    selector: 'ui-notification-popup',
    templateUrl: './notification-popup.component.html',
    styleUrl: './notification-popup.component.scss',
    imports: [
        MenuButtonComponent,
        MarkdownDirective,
        RouterLink,
        DatePipe
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationPopupComponent implements OnInit {
  private router = inject(Router);
  private ns = inject(NotificationsService);
  private san = inject(DomSanitizer);

  readonly menus = viewChildren<MenuButtonComponent>('menu');

  readonly action = output<IMenuButtonAction>();

  items = model.required<IHeaderNotification[]>();
  newCount = computed(() => this.items().filter(i => !i.status).length);
  itemWithActions = computed(() => {
    return this.items().map(it => {
      it.actions = [
        {id: 'markAsRead', text: 'Mark as read'},
        // {id: 'unsubscribe', text: 'Unsubscribe'}
      ];
      if (it.status) {
        it.actions.splice(0, 1);
      }
      return {
        ...it
      }
    })
  });

  rowActions: IMenuButtonItem[] = [
    {id: 'markAsRead', text: 'Mark as read'},
    {id: 'unsubscribe', text: 'Unsubscribe'}
  ];

  get globalActions(): IMenuButtonItem[] {
    const mark = {id: 'markAsRead', text: 'Mark all as read'};
    return [
      ...(this.newCount() ? [mark] : []),
      ...(this.ns.showSettings() ? [{id: 'settings', text: 'Notification settings'}] : [])
    ];
  }

  ngOnInit() {
  }

  onGlobalAction(action: IMenuButtonAction) {
    this.action.emit(action);
    switch (action.item.id) {
      case 'settings':
        this.router.navigateByUrl('/portal/profile/subscriptions');
        break;
      case 'markAsRead':
        this.markAllAsRead();
        break
    }
  }

  onItemAction(e: IMenuButtonAction, notify: IHeaderNotification) {
    switch (e.item.id) {
      case 'markAsRead':
        void this.markAsRead(notify);
        break;
    }
  }

  closeAllMenus() {
    const menus = this.menus();
    if (!menus) {
      return;
    }
    menus.forEach(m => m.close());
  }

  private async markAllAsRead() {
    this.ns.onMarkAsRead.emit(this.items().filter(n => !n.status).map(n => n.id));
    this.items.update(items => {
      items.forEach(n => n.status = 1);
      return [...items];
    });
  }

  async markAsRead(notify: IHeaderNotification) {
    this.ns.onMarkAsRead.emit([notify.id]);
    this.items.update(items => {
      const n = items.find(i => i.id == notify.id);
      if (n) {
        n.status = 1;
      }
      return [...items];
    });
  }

  getUrl(n: IHeaderNotification) {
    return n.url.split('?')[0];
  }

  getQueryParams(n: IHeaderNotification) {
    const params: { [key: string]: string } = {};
    const searchParams = new URLSearchParams(n.url.split('?')?.[1]?.split('#')?.[0] || '');

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  getFragment(n: IHeaderNotification) {
    return n.url.split('?')?.[1]?.split('#')?.[1];
  }
}
